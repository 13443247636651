import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import './plugins/meta';
import axios from './plugins/axios';

store.$axios = axios;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
