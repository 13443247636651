import Vue from 'vue';
import Vuex from 'vuex';

import {
  cvvInputId,
  numberInputId,
  getUniqueId,
  getWindowName,
} from '../helpers/names';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    integerMaskSymbol: '0',
    cvvInputId,
    numberInputId,
    cvvFormId: 'cvv-form',
    numberFormId: 'number-form',
    cardRegistratorPath: '/api/v1/register',
    cardPaymentPath: '/api/v1/order',
  },
  getters: {
    getUniqueId: () => field => getUniqueId(field),
    getWindowName: () => (field, id) => getWindowName(field, id),
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  },
});
