const numberInputId = 'number';
const cvvInputId = 'cvv';

const getNamePart = field => `wert-${field}-frame-`;

const getUniqueId = field => {
  const namePart = getNamePart(field);

  return window.name.replace(namePart, '');
};

const getWindowName = (field, id) => {
  const namePart = getNamePart(field);

  return `${namePart}${id}`;
};

export {
  numberInputId,
  cvvInputId,
  getNamePart,
  getUniqueId,
  getWindowName,
};
