import Vue from 'vue';
import VueAxios from 'vue-axios';

import axios from 'axios';

const axiosInstance = axios.create({
  // baseURL: '',
  withCredentials: true,
});

Vue.use(VueAxios, axiosInstance);

export default axiosInstance;
