<template>
  <form
    :id="cvvFormId"
    accept-charset="UTF-8"
    action="javascript:void(0);"
    onsubmit="return false;"
    @reset="onReset"
  >
    <imask-input
      :ref="cvvInputId"
      :id="cvvInputId"
      name="card-cvv"
      inputmode="numeric"
      autocomplete="off"
      class="secure-input input"
      v-model="cardCVV"
      :mask="codeMask"
      @blur="onBlur"
      @focus="onFocus"
      @mouseover="onMouseOver"
      @mouseout="onMouseOut"
      @keyup="onKeyUp"
      @keydown="onKeyDown"
    />
  </form>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { IMaskComponent } from 'vue-imask';

export default {
  name: 'CVV',
  metaInfo: {
    title: 'Wert CVV Frame',
  },
  components: {
    'imask-input': IMaskComponent,
  },
  data() {
    return {
      value: '',
      maskLength: 4,
    };
  },
  computed: {
    ...mapState({
      cvvInputId: state => state.cvvInputId,
      cvvFormId: state => state.cvvFormId,
      numberInputId: state => state.numberInputId,
      integerMaskSymbol: state => state.integerMaskSymbol,
    }),
    ...mapGetters(['getUniqueId', 'getWindowName']),
    uniqueId() {
      return this.getUniqueId(this.cvvInputId);
    },
    numberWindowName() {
      return this.getWindowName(this.numberInputId, this.uniqueId);
    },
    cardCVV: {
      get() {
        return this.value;
      },
      set(value) {
        this.value = value;
      },
    },
    codeMask() {
      return new Array(this.maskLength + 1).join(this.integerMaskSymbol);
    },
  },
  mounted() {
    window.notifyEvent = this.notifyEvent;

    this.findNumberWindow();
  },
  methods: {
    findNumberWindow() {
      let messageInterval;

      const establishCommunication = () => {
        try {
          if (window.parent.frames[this.numberWindowName]
            && window.parent.frames[this.numberWindowName].setUpCvv) {
            window.parent.frames[this.numberWindowName].setUpCvv();

            clearInterval(messageInterval);
          }
        // eslint-disable-next-line
        } catch {}
      };

      messageInterval = setInterval(establishCommunication, 5);
    },
    onBlur() {
      this.notifyEvent(this.cvvInputId, 'blur', false);

      this.removeSelection();
    },
    onFocus() {
      this.notifyEvent(this.cvvInputId, 'focus', false);
    },
    onMouseOver() {
      this.notifyEvent(this.cvvInputId, 'mouseover', true);
    },
    onMouseOut() {
      this.notifyEvent(this.cvvInputId, 'mouseout', true);
    },
    onKeyUp(event) {
      if (!event) return;

      if (event.keyCode === 13) {
        event.preventDefault();
        event.stopPropagation();

        this.notifyEvent(this.cvvInputId, 'enter');
      } else if (event.keyCode === 27) {
        event.preventDefault();
        event.stopPropagation();

        this.notifyEvent(this.cvvInputId, 'escape');
      } else if (event.keyCode === 38 || event.keyCode === 40) {
        event.preventDefault();
        event.stopPropagation();
      }
    },
    onKeyDown(event) {
      if (!event) return;

      if (event.keyCode === 9 && event.shiftKey) {
        this.notifyEvent(this.cvvInputId, 'shiftTab');
      } else if (event.keyCode === 9) {
        this.notifyEvent(this.cvvInputId, 'tab');
      } else if (event.keyCode === 27) {
        event.preventDefault();
        event.stopPropagation();

        this.notifyEvent(this.cvvInputId, 'escape');
      } else if (event.keyCode === 38 || event.keyCode === 40) {
        event.preventDefault();
        event.stopPropagation();
      }
    },
    onReset(event) {
      event.preventDefault();
      event.stopPropagation();

      this.cardCVV = '';
    },
    removeSelection() {
      if (window.getSelection) {
        window.getSelection().removeAllRanges();
      }
    },
    notifyEvent(name, notification, addActiveField) {
      let activeField = '';

      if (addActiveField && document.hasFocus()) {
        activeField = this.cvvInputId;
      }

      window.parent.postMessage({
        id: this.uniqueId,
        type: 'notifyEvent',
        name,
        notification,
        activeField,
      }, '*');
    },
  },
};
</script>
