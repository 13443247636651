<template>
  <form
    :id="numberFormId"
    accept-charset="UTF-8"
    action="javascript:void(0);"
    onsubmit="return false;"
  >
    <imask-input
      :ref="numberInputId"
      :id="numberInputId"
      type="text"
      name="card-number"
      inputmode="numeric"
      class="input"
      v-model="cardNumber"
      :disabled="paymentMode"
      :hidden="paymentMode"
      :mask="numberMask"
      :unmask="false"
      @blur="onBlur"
      @focus="onFocus"
      @mouseover="onMouseOver"
      @mouseout="onMouseOut"
      @keyup="onKeyUp"
      @keydown="onKeyDown"
      @paste="onPaste"
    />
  </form>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { IMaskComponent } from 'vue-imask';

import cardValidator from 'card-validator';

export default {
  name: 'Number',
  metaInfo: {
    title: 'Wert Number Frame',
  },
  components: {
    'imask-input': IMaskComponent,
  },
  data() {
    return {
      value: '',
      rawValue: '',
      paymentMode: false,
      defaultCVVLength: 3,
      cardValidation: {},
      cvvField: null,
      cvvForm: null,
    };
  },
  computed: {
    ...mapState({
      cvvInputId: state => state.cvvInputId,
      cvvFormId: state => state.cvvFormId,
      numberInputId: state => state.numberInputId,
      numberFormId: state => state.numberFormId,
      integerMaskSymbol: state => state.integerMaskSymbol,
      cardRegistratorPath: state => state.cardRegistratorPath,
      cardPaymentPath: state => state.cardPaymentPath,
    }),
    ...mapGetters(['getUniqueId', 'getWindowName']),
    uniqueId() {
      return this.getUniqueId(this.numberInputId);
    },
    cvvWindowName() {
      return this.getWindowName(this.cvvInputId, this.uniqueId);
    },
    cardNumber: {
      get() {
        return this.value;
      },
      set(value) {
        this.value = value;
        this.rawValue = value.replace(/\s/g, '');

        this.cardValidation = cardValidator.number(this.rawValue);
      },
    },
    numberMask() {
      const maskParts = [];
      let length = 16;
      let gaps = [4, 8, 12];

      if (this.cardValidation.card) {
        gaps = this.cardValidation.card.gaps;
        length = Math.max(...this.cardValidation.card.lengths);
      }

      for (let i = 0; i <= gaps.length; i++) {
        const startPosition = gaps[i - 1] || 0;
        const endPosition = gaps[i] || length;
        const partLength = endPosition - startPosition;

        const part = new Array(partLength + 1).join(this.integerMaskSymbol);

        if (part) maskParts.push(part);
      }

      return maskParts.join(' ');
    },
  },
  mounted() {
    this.setNumberField();

    window.setUpCvv = this.setCvvField;
    window.onerror = this.consoleError;

    window.addEventListener('message', this.onMessageReceived);
  },
  beforeDestroy() {
    window.removeEventListener('message', this.onMessageReceived);

    this.resetCvvField();
  },
  methods: {
    setPaymentMode(options = {}) {
      this.cardNumber = options.cardNumberBeginning || '';
      this.paymentMode = true;

      this.validate();
    },
    onBlur() {
      this.notifyEvent(this.numberInputId, 'blur', false);

      this.removeSelection();
    },
    onFocus() {
      this.notifyEvent(this.numberInputId, 'focus', false);
    },
    onMouseOver() {
      this.notifyEvent(this.numberInputId, 'mouseover', true);
    },
    onMouseOut() {
      this.notifyEvent(this.numberInputId, 'mouseout', true);
    },
    onKeyUp(event) {
      if (event.keyCode === 13) {
        event.preventDefault();
        event.stopPropagation();

        this.notifyEvent(this.numberInputId, 'enter');
      } else if (event.keyCode === 27) {
        event.preventDefault();
        event.stopPropagation();

        this.notifyEvent(this.numberInputId, 'escape');
      }

      this.onInput(event);
    },
    onKeyDown(event) {
      if (event.keyCode === 9 && event.shiftKey) {
        this.notifyEvent(this.numberInputId, 'shiftTab');
      } else if (event.keyCode === 9) {
        this.notifyEvent(this.numberInputId, 'tab');
      }

      this.onInput(event);
    },
    onPaste(event) {
      setTimeout(() => this.onInput(event), 0);
    },
    onInput(event) {
      if (event.type !== 'keydown' || event.keyCode === 9) {
        const properties = this.inputProperties(event);

        this.message({
          type: 'input',
          properties,
        });
      }
    },
    removeSelection() {
      if (window.getSelection) {
        window.getSelection().removeAllRanges();
      }
    },
    setNumberField() {
      this.numberField = document.getElementById(this.numberInputId);
    },
    setCvvField() {
      const cvvWindow = this.getCvvWindow();

      cvvWindow.onerror = this.consoleError;

      this.cvvField = cvvWindow.document.getElementById(this.cvvInputId);
      this.cvvForm = cvvWindow.document.getElementById(this.cvvFormId);

      this.cvvField.addEventListener('keydown', this.onInput);
      this.cvvField.addEventListener('keyup', this.onInput);

      this.message({ type: 'iframesReady' });
    },
    resetCvvField() {
      this.cvvField.removeEventListener('keydown', this.onInput);
      this.cvvField.removeEventListener('keyup', this.onInput);
    },
    getCvvWindow() {
      return window.parent.frames[this.cvvWindowName];
    },
    onMessageReceived(message) {
      if (typeof message.data !== 'object') return;

      switch (message.data.type) {
        case 'style':
          this.applyStyles(message.data.where, message.data.what);

          break;
        case 'placeholder':
          this.setPlaceholder(message.data.where, message.data.what);

          break;
        case 'focus':
          this.focus(message.data.what);

          break;
        case 'validate':
          this.validate();

          break;
        case 'reset':
          this.reset();

          break;
        case 'createCard':
          this.createCard(message.data.data, message.data.headers);

          break;
        case 'createPayment':
          this.createPayment(message.data.data, message.data.headers, message.data.options);

          break;
        case 'setPaymentMode':
          this.setPaymentMode(message.data.options);

          break;
        default:
          break;
      }
    },
    applyStyles(where, what) {
      if (!where || !what) return;

      const element = this.getElement(where);

      if (!element) return;

      Object.entries(what).forEach(([styleName, styleValue]) => {
        element.style.setProperty(styleName, styleValue);
      });
    },
    setPlaceholder(where, what) {
      if (!where || !what) return;

      const element = this.getElement(where);

      if (!element) return;

      element.setAttribute('placeholder', what);
    },
    getElement(which) {
      switch (which) {
        case this.numberInputId:
          return this.numberField;
        case this.cvvInputId:
          return this.cvvField;
        default:
          return null;
      }
    },
    reset() {
      this.cardNumber = '';

      this.cvvForm.reset();
    },
    focus(element) {
      switch (element) {
        case this.numberInputId:
          this.numberField.focus();

          break;
        case this.cvvInputId:
          this.cvvField.focus();

          break;
        case 'iframe':
          window.focus();

          break;
        default:
          break;
      }
    },
    inputProperties(event) {
      let properties;

      if (!this.cardValidation.card) {
        properties = {
          cardType: '',
          numberValid: false,
          cvvValid: this.paymentMode
            ? this.cvvField && (this.cvvField.value.length === this.defaultCVVLength)
            : false,
        };
      } else {
        properties = {
          cardType: this.cardValidation.card.type,
          numberValid: this.cardValidation.isValid,
          cvvValid: this.cvvField
            && this.cvvField.value.length === this.cardValidation.card.code.size,
        };
      }

      return {
        ...properties,
        numberLength: this.rawValue.length,
        cvvLength: this.cvvField && this.cvvField.value.length,
        activeElement: event ? event.target.id : undefined,
      };
    },
    validate() {
      const properties = this.inputProperties();

      this.message({
        type: 'validation',
        properties,
      });
    },
    createCard(data, headers) {
      const {
        numberValid, numberLength,
      } = this.inputProperties();

      if (!numberValid) {
        const errors = [];

        if (!numberValid) {
          errors.push({
            type: numberLength ? 'invalid' : 'blank',
            fieldId: this.numberInputId,
          });
        }

        this.message({
          type: 'createCardResponse',
          error: { message: errors.map(e => `${e.fieldId} is ${e.type}`).join(', ') },
        });

        return;
      }

      const finalData = {
        number: this.rawValue,
        ...data,
      };

      this.axios.post(this.cardRegistratorPath, finalData, { headers })
        .then(response => {
          this.message({
            type: 'createCardResponse',
            data: response.data.body,
          });
        })
        .catch(error => {
          this.message({
            type: 'createCardResponse',
            error: {
              response: JSON.parse(JSON.stringify(error.response)),
            },
          });
        });
    },
    createPayment(data, headers, { cvvIgnore } = { cvvIgnore: false }) {
      const { cvvValid, cvvLength } = this.inputProperties();

      if (!cvvValid && !cvvIgnore) {
        const errors = [{
          type: cvvLength ? 'invalid' : 'blank',
          fieldId: this.cvvInputId,
        }];

        this.message({
          type: 'createPaymentResponse',
          error: { message: errors.map(e => `${e.fieldId} is ${e.type}`).join(', ') },
        });

        return;
      }

      const finalData = cvvIgnore
        ? data
        : {
          ...data,
          cvv: this.cvvField.value,
        };

      this.axios.post(this.cardPaymentPath, finalData, { headers })
        .then(response => {
          this.message({
            type: 'createPaymentResponse',
            data: response.data.body,
          });
        })
        .catch(error => {
          this.message({
            type: 'createPaymentResponse',
            error: {
              response: JSON.parse(JSON.stringify(error.response)),
            },
          });
        });
    },
    notifyEvent(name, notification, addActiveField) {
      let activeField = '';
      const cvvWindow = this.getCvvWindow();

      if (addActiveField && document.hasFocus()) {
        activeField = this.numberInputId;
      } else if (addActiveField && cvvWindow && cvvWindow.document.hasFocus()) {
        activeField = this.cvvInputId;
      }

      this.message({
        type: 'notifyEvent',
        name,
        notification,
        activeField,
      });
    },
    errors(errors) {
      this.message({
        type: 'errors',
        errors,
      });
    },
    consoleError(msg, url, line, col, error) {
      const errorProps = {
        msg,
        url,
        line,
        col,
        error,
      };

      this.message({
        type: 'consoleError',
        properties: JSON.parse(JSON.stringify(errorProps)),
      });
    },
    message(message) {
      window.parent.postMessage({
        id: this.uniqueId,
        ...message,
      }, '*');
    },
  },
};
</script>
