import Vue from 'vue';
import VueRouter from 'vue-router';

import { numberPath, cvvPath } from '../helpers/routes';

import CVVFrame from '../views/CVV.vue';
import NumberFrame from '../views/Number.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: numberPath,
    name: 'number',
    component: NumberFrame,
  },
  {
    path: cvvPath,
    name: 'cvv',
    component: CVVFrame,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
